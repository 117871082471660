<template>
  <b-row class="mt-1">
    <b-col cols="2">
      <p>Категория</p>
    </b-col>
    <b-col cols="10">
      <b-form-select
        size="sm"
        :options="car_categories"
        :value="value.filter_category_car_id"
        @input="updateModel($event, 'filter_category_car_id')" />
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: ["value"],
  name: "category",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      car_categories: [],
    };
  },
  methods: {
    updateModel(value, name) {
      this.$emit("change", { val: value, field: name });
    },
  },
  mounted() {
    this.$http.get("directory/cars").then((res) => {
      for (let i of res.data.car_categories) {
        this.car_categories.push({
          value: i.id,
          text: i.name,
        });
      }
    });
  },
};
</script>
