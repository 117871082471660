let draggableData = {
  storageTabsName: "tabs",
  storageCurrentTabIdName: "currentTabId",
  storageNextTabIdName: "nextTabId",
};

let Tabs = JSON.parse(localStorage.getItem(draggableData.storageTabsName));
let allTabs = Tabs;
let defaultTab = [
  {
    id: 1,
    name: "Главная",
    path: "/",
    query: undefined,
  },
];
if (Tabs == null || Tabs.length < 1) {
  Tabs = [];
  allTabs = Tabs.concat(defaultTab);
}
let currTabId = localStorage.getItem(draggableData.storageCurrentTabIdName);
if (currTabId == null || Tabs == null || Tabs.length < 1) {
  currTabId = 1;
}
let nextTab = localStorage.getItem(draggableData.storageNextTabIdName);
if (nextTab == null || Tabs == null || Tabs.length < 1) {
  nextTab = 2;
}
export default {
  namespaced: true,
  state: {
    tabs: allTabs,
    currentTabId: currTabId,
    nextTabId: nextTab,
  },
  getters: {},
  mutations: {
    CLEAR_TAB(state) {
      state.tabs = [
        {
          id: 1,
          name: "Главная",
          path: "/",
          query: undefined,
        },
      ];
    },
    ADD_TAB(state, val) {
      if (!state.tabs.some((el) => el.name === val[0])) {
        state.tabs.push({
          id: state.nextTabId,
          name: val[0],
          path: val[1],
          query: val[2],
        });
        state.currentTabId = state.nextTabId;
        state.nextTabId++;
        localStorage.setItem(
          draggableData.storageTabsName,
          JSON.stringify(state.tabs)
        );
        localStorage.setItem(
          draggableData.storageCurrentTabIdName,
          state.currentTabId
        );
        localStorage.setItem(
          draggableData.storageNextTabIdName,
          state.nextTabId
        );
      }
    },
    CLOSE_TAB(state, val) {
      const index = state.tabs.findIndex((tab) => tab.id === val);
      if (index !== -1) {
        state.tabs.splice(index, 1);
        if (state.tabs.length > 0) {
          state.currentTabId = state.tabs[Math.max(index - 1, 0)].id;
        } else {
          state.currentTabId = 1;
        }
        localStorage.setItem(
          draggableData.storageTabsName,
          JSON.stringify(state.tabs)
        );
        localStorage.setItem(
          draggableData.storageCurrentTabIdName,
          state.currentTabId
        );
      }
      state.nextTabId--;
    },
    CHANGE_TAB(state, val) {
      state.currentTabId = val;
      localStorage.setItem(
        draggableData.storageCurrentTabIdName,
        state.currentTabId
      );
    },
  },
  actions: {},
};
