<template>
  <b-row>
    <b-col cols="2">
      <p>Класс авто</p>
    </b-col>
    <b-col cols="5">
      <b-form-select
        size="sm"
        :options="firstNameOptions"
        :value="value.filter_car_class_condition"
        @input="updateModel($event, 'filter_car_class_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input
        placeholder="Класс авто"
        size="sm"
        :value="value.filter_car_class"
        @update="updateModel($event, 'filter_car_class')" />
    </b-col>
  </b-row>
</template>
<script>
import filterOptions from "@/utils/constants/filerConstants/parametrs";
export default {
  props: ["value"],
  name: "car-class",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      firstNameOptions: filterOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      this.$emit("change", { val: value, field: name });
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "dop_options" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_car_class";
      this.updateModel(value, name);
    }
  },
};
</script>
