<template>
  <b-row>
    <b-col cols="2">
      <p>Откуда</p>
    </b-col>
    <b-col cols="5">
      <b-form-select
        size="sm"
        :options="from_addressOptions"
        :value="value.filter_from_address_condition"
        @input="updateModel($event, 'filter_from_address_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input
        placeholder="Откуда"
        size="sm"
        :value="value.filter_from_address"
        @input="updateModel($event, 'filter_from_address')" />
    </b-col>
  </b-row>
</template>
<script>
import filterOptions from "@/utils/constants/filerConstants/parametrs";
export default {
  props: ["value"],
  name: "from_address",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      from_addressOptions: filterOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      if (name === "filter_from_address" || name === "filter_from_address_condition") {
        if (value === "") {
          value = null;
        }
        this.$emit("change", { val: value, field: name });
      }

      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "from_address.title" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_from_address";
      this.updateModel(value, name);
    }
  },
};
</script>
