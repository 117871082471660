export default {
  state: {
    filterPlus: null,
    keyFilter: null,
    tableIndex: null,
    addFilterPlus: false,
    filterValue: null,
  },
  mutations: {
    setdataTable(state) {
      state.tableIndex = null;
    },
    setFilterValue(state, newValue) {
      state.filterValue = newValue;
    },
    SET_FILTER_INDEX(state, newValue) {
      state.tableIndex = newValue;
    },
  },
};
