import ability from "./ability";
import useJwt from "@/@core/auth/jwt/jwtDefaultConfig";

export const canNavigate = (to) =>
  to.matched.some((route) =>
    ability.can(route.meta.action || "read", route.meta.resource)
  );

export const canGetAccess = (to) => {
  let userData = JSON.parse(localStorage.getItem(useJwt.storageUserDataName));
  let result = false;
  if (userData) {
    userData.access.forEach((el) => {
      if (el.route === to.name) {
        result = true;
      }
    });
  }
  return result;
};

export const _ = undefined;
