<template>
  <div v-show="show" ref="caller" id="caller" class="caller">
    <div id="callerheader" ref="callerheader" class="header">
      <!-- <span @click="getCallHistory" class="close-button" style="margin-right: 10px;">0</span> -->
      <span @click="$store.commit('caller/UPDATE_CALLER_STATE', false)" class="close-button">x</span>
    </div>
    <div>
      <div class="caller-body">
        <div class="w-100 position-relative">
          <input @keyup.enter="call()" v-model="number" placeholder="Номер телефона" class="phone-number" type="text" />
          <span v-if="session && confirmed" class="timer">
            {{ time }}
          </span>
        </div>
        <div class="row">
          <div class="col-12 session-info justifi" v-if="session">
            <router-link :to="{ name: 'orders' }">
              <button class="session-action d-flex align-items-center justify-content-center" @click="numberPhone()">
                Найти заказ
              </button>
            </router-link>
            <router-link :to="{ name: 'drivers' }">
              <button class="session-action d-flex align-items-center justify-content-center" @click="numberPhone()">
                Найти водителя
              </button>
            </router-link>
            <router-link :to="{ name: 'driver-profiles' }">
              <button class="session-action d-flex align-items-center justify-content-center" @click="numberPhone()">
                Найти анкету водителя
              </button>
            </router-link>
            <router-link :to="{ name: 'memos' }">
              <button class="session-action d-flex align-items-center justify-content-center" @click="numberPhone()">
                Найти служебную запись
              </button>
            </router-link>
            <button @click="muteOrUmmute()" class="session-action d-flex align-items-center justify-content-center">
              <span>Микрофон</span>
              <i v-if="!$store.state.caller.isMuted" class="ml-2 ri-mic-fill"></i>
              <i v-else class="ml-2 ri-mic-off-fill"></i>
            </button>
          </div>
          <div class="control-container col-12">
            <button v-if="!session" @click="call()" class="control-btn w-100 rounded-pill" style="margin-top: 10px">
              Вызов
            </button>
            <div class="w-100 d-flex flex-wrap" v-else-if="session.direction === 'incoming' && confirmed == false">
              <button @click="acceptCall()" class="control-btn w-100 rounded-pill">Принять</button>
              <button @click="hangup()" class="control-btn hangup w-100 rounded-pill">Отклонить</button>
            </div>
            <button v-else @click="hangup()" class="control-btn hangup w-100 rounded-pill">Завершить</button>
          </div>
        </div>
      </div>
      <audio ref="audioPlayer" src="./audio.mp3"></audio>
    </div>
    <div class="historyCall" v-if="!session">
      <ul
        v-for="item in historyCall"
        :key="item.id"
        style="list-style: none; margin: 0; padding: 0; padding-left: 10px">
        <li style="margin: 0; cursor: pointer" @dblclick="call(item.called_number)" class="hoverLi">
          <div style="display: flex">
            <p class="row" v-if="item.call_direction === 'out'" style="font-size: 26px; font-weight: 900">&#8595;</p>
            <p class="row" v-if="item.call_direction === 'in'" style="font-size: 26px; font-weight: 900">&#8593;</p>
            <p>{{ item.called_number }}</p>
          </div>
          <span>{{ item.call_date | removeSeconds }}</span>
          <hr style="margin: 0; padding: 0" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import JsSIP from "jssip";
import ringtone from "@/assets/ringtone.wav";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { setTimeout } from "timers";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter.js";
import { mapMutations } from "vuex";
export default {
  props: ["show"],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      confirmed: false,
      caller: null,
      callerHeader: null,
      configuration: {
        mediaConstraints: {
          audio: true,
          video: false,
        },
        rtcOfferConstraints: {
          offerToReceiveAudio: true,
          offerToReceiveVideo: false,
        },
      },
      number: "",
      userData: JSON.parse(localStorage.getItem("userData")),
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      remoteAudio: null,
      ringtoneAudio: null,
      session: null,
      socket: null,
      seconds: null,
      timer: null,
      ua: null,
      historyCall: "",
      user: JSON.parse(localStorage.getItem("sipConfig")),
    };
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    this.caller = this.$refs.caller;
    this.callerHeader = this.$refs.callerheader;
    if (this.$refs.callerheader) {
      // if present, the header is where you move the DIV from:
      this.$refs.callerheader.onmousedown = this.dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      this.caller.onmousedown = this.dragMouseDown;
    }
    if (localStorage.getItem("sipStatus") === "true") {
      this.sipInit();
    }
    window.addEventListener("keyup", (event) => {
      if (event.ctrlKey && event.keyCode === 77) {
        this.muteOrUmmute();
      }
    });
    this.getCallHistory();
  },
  methods: {
    ...mapMutations(["setFilterValue"]),
    numberPhone() {
      const newValue = this.number;
      this.setFilterValue(newValue);
    },
    playAudio() {
      this.$refs.audioPlayer.play();
    },
    handleKeyDown(event) {
      const isCtrlPressed = event.ctrlKey || event.metaKey;

      const isMinusPressed = event.keyCode === 109;

      if (isCtrlPressed && isMinusPressed) {
        event.preventDefault();
        this.hangup();
      }
    },
    async getCallHistory() {
      if (this.user?.user) {
        try {
          const res = await this.$http.get(`asterisk-api/last-call?sip_number=${this.user.user}`);
          this.historyCall = res.data;
        } catch (error) {
          console.error("Ошибка при получении истории звонков:", error);
        }
      }
    },
    call(num) {
      if (this.session) return;
      if (num) {
        this.number = num;
      }
      if (!this.number) {
        return;
      }
      const eventHandlers = {
        progress: (e) => {
          this.session.connection.ontrack = (e) => {
            this.remoteAudio.srcObject = e.streams[0];
          };
        },
        failed: (e) => {
          this.number = "";
          this.$store.commit("caller/UPDATE_PHONE_NUMBER", null);
          this.session = null;
          this.confirmed = false;
          clearInterval(this.timer);
          this.seconds = 0;
          this.$store.commit("caller/UPDATE_IS_MUTED", false);
          this.getCallHistory();
        },
        ended: (e) => {
          this.number = "";
          this.$store.commit("caller/UPDATE_PHONE_NUMBER", null);
          this.session = null;
          this.confirmed = false;
          clearInterval(this.timer);
          this.seconds = 0;
          this.$store.commit("caller/UPDATE_IS_MUTED", false);
          this.getCallHistory();
        },
        confirmed: (e) => {
          this.confirmed = true;
          this.timer = setInterval(() => {
            this.seconds += 1;
          }, 1000);
          this.playAudio();
        },
      };
      const options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: false },
      };
      this.session = this.ua.call(this.number, options);
    },
    closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    },
    dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;
      document.onmouseup = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();

      // calculate the new cursor position:
      this.pos1 = this.pos3 - e.clientX;
      this.pos2 = this.pos4 - e.clientY;
      this.pos3 = e.clientX;
      this.pos4 = e.clientY;

      // get the window dimensions
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      // calculate the element's new position within the window boundaries:
      const newTop = this.caller.offsetTop - this.pos2;
      const newLeft = this.caller.offsetLeft - this.pos1;

      // check if the new position exceeds the window boundaries
      const maxTop = windowHeight - this.caller.offsetHeight;
      const maxLeft = windowWidth - this.caller.offsetWidth;

      // update the element's position while ensuring it stays within the window boundaries:
      this.caller.style.top = Math.max(0, Math.min(newTop, maxTop)) + "px";
      this.caller.style.left = Math.max(0, Math.min(newLeft, maxLeft)) + "px";
    },
    hangup() {
      if (this.session) {
        this.session.terminate();
        this.getCallHistory();
      }
    },
    acceptCall() {
      this.playAudio();
      this.session.answer(this.configuration);
      this.session.connection.ontrack = (e) => {
        this.remoteAudio.srcObject = e.streams[0];
        this.remoteAudio.play();
      };
    },
    sipInit() {
      this.$store.commit("caller/UPDATE_LOADER", true);
      let sipConfig = JSON.parse(localStorage.getItem("sipConfig"));
      if (!sipConfig) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Заполните данные SIP!",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
        setTimeout(() => {
          this.$store.commit("caller/UPDATE_STATUS", false);
          this.$store.commit("caller/UPDATE_LOADER", false);
        }, 2000);
        return;
      }

      this.socket = new JsSIP.WebSocketInterface(
        `wss://${sipConfig.url}${sipConfig.port ? ":" + sipConfig.port : ""}/ws`
      );
      this.remoteAudio = new window.Audio();
      this.ringtoneAudio = new Audio(ringtone);
      this.ringtoneAudio.autoplay = false;
      this.ringtoneAudio.loop = "loop";
      this.remoteAudio.autoplay = true;
      try {
        this.ua = new JsSIP.UA({
          sockets: [this.socket],
          display_name: sipConfig?.user,
          uri: `${sipConfig.user}@${sipConfig.url}`,
          password: `${sipConfig.secret}`,
          session_timers: false,
        });
      } catch (err) {
        setTimeout(() => {
          this.$store.commit("caller/UPDATE_STATUS", false);
          this.$store.commit("caller/UPDATE_LOADER", false);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Не удалось подключится к серверу",
              icon: "XIcon",
              variant: "danger",
              text: "Проверьте настройки SIP",
            },
          });
        }, 2000);
      }

      this.ua.on("connected", (e) => {});

      this.ua.on("registered", (e) => {
        this.$store.commit("caller/UPDATE_LOADER", false);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "SIP зарегистрирован",
            icon: "CheckIcon",
            variant: "success",
            text: "",
          },
        });
      });

      this.ua.on("disconnected", (e) => {});

      this.ua.on("unregistered", (e) => {
        this.$store.commit("caller/UPDATE_LOADER", false);
        this.$store.commit("caller/UPDATE_STATUS", false);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "SIP отключён",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
      });

      this.ua.on("registrationFailed", (e) => {
        this.$store.commit("caller/UPDATE_LOADER", false);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Неправильные данные SIP",
            icon: "XIcon",
            variant: "danger",
            text: "",
          },
        });
        setTimeout(() => {
          this.$store.commit("caller/UPDATE_STATUS", false);
        }, 2000);
      });
      this.ua.start();
      this.ua.on("newRTCSession", (data) => {
        this.session = data.session;
        if (this.session.direction === "incoming") {
          this.number = data.request.from._uri._user;
          this.$store.commit("caller/UPDATE_PHONE_NUMBER", data.request.from._uri._user);
          this.ringtoneAudio.play();
          this.session.on("accepted", (e) => {});
          this.session.on("confirmed", (e) => {
            this.confirmed = true;
            this.$store.commit("caller/UPDATE_SESSION_STATE", true);
            this.ringtoneAudio.pause();
            this.timer = setInterval(() => {
              this.seconds += 1;
            }, 1000);
            GlobalEventEmitter.$emit("confirmedCall");
          });
          this.session.on("ended", (e) => {
            this.$store.commit("caller/UPDATE_SESSION_STATE", false);
            this.session = null;
            this.ringtoneAudio.pause();
            this.confirmed = false;
            this.number = "";
            this.$store.commit("caller/UPDATE_IS_MUTED", false);
            this.$store.commit("caller/UPDATE_PHONE_NUMBER", null);
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", false);
            clearInterval(this.timer);
            this.seconds = 0;
            this.getCallHistory();
          });
          this.session.on("failed", (e) => {
            this.$store.commit("caller/UPDATE_SESSION_STATE", false);
            this.ringtoneAudio.pause();
            this.session = null;
            this.confirmed = false;
            this.number = "";
            this.$store.commit("caller/UPDATE_IS_MUTED", false);
            this.$store.commit("caller/UPDATE_IS_INITIATOR_STATE", false);
            this.$store.commit("caller/UPDATE_PHONE_NUMBER", null);
            this.seconds = 0;
            clearInterval(this.timer);
            this.getCallHistory();
          });
          if (this.$route.path == "/order/index" && this.userData.role_id == 1) {
            this.acceptCall();
            this.$root.$emit("openModalOrder", data.request.from._uri._user);
          }
          if (this.$store.state.caller.isInitiator) {
            this.acceptCall();
          }
          this.$store.commit("caller/UPDATE_CALLER_STATE", true);
        }
      });
    },
    muteOrUmmute() {
      if (this.session && !this.session.isMuted().audio) {
        this.session.mute({ audio: true });
        this.$store.commit("caller/UPDATE_IS_MUTED", true);
      } else if (this.session && this.session.isMuted().audio) {
        this.session.unmute({ audio: true });
        this.$store.commit("caller/UPDATE_IS_MUTED", false);
      }
    },
  },
  computed: {
    status() {
      return this.$store.state.caller.status;
    },
    time() {
      return (
        parseInt(this.seconds / 60) + ":" + (this.seconds % 60 < 10 ? "0" + (this.seconds % 60) : this.seconds % 60)
      );
    },
  },
  created() {
    // adds the event listener function that will handle the event
    GlobalEventEmitter.$on("originateCall", (e) => {
      this.number = e;
      this.call();
    });
    GlobalEventEmitter.$on("hangUpCall", () => {
      this.hangup();
    });
  },
  beforeDestroy() {
    // removes event listener
    GlobalEventEmitter.$off("originateCall");
    GlobalEventEmitter.$off("hangUpCall");
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  watch: {
    status(newValue, oldValue) {
      if (newValue) {
        this.sipInit();
      } else {
        if (this.ua) {
          this.ua.stop();
        }
      }
    },
  },
  filters: {
    removeSeconds(value) {
      return value.split(":")[0] + ":" + value.split(":")[1];
    },
  },
};
</script>
<style scoped>
.caller {
  width: 260px;
  /* height: 350px; */
  /* overflow: scroll; */
  position: absolute;
  position: fixed;
  top: 40px;
  right: 0;
  z-index: 9999;
  background-color: #fff;
  border-width: 1px;
}
.caller .header {
  height: 20px;
  position: relative;
  cursor: move;
  width: 100%;
  background-color: #c4daff;
  border: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.close-button {
  position: absolute;
  right: 0.25rem;
  bottom: 0;
  cursor: pointer;
}
.caller-body {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.phone-number {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
}
.phone-number:focus {
  outline: none;
}
.timer {
  position: absolute;
  top: 8px;
  right: 8px;
}
.session-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 2rem;
}
.session-action {
  width: 250px;
  outline: none;
  border: none;
  background-color: rgb(215, 215, 215);
  padding: 8px 20px;
  margin-bottom: 10px;
  border-radius: 100px;
}
.session-action:active {
  background-color: rgb(200, 200, 200);
}
.number-pad {
  margin-bottom: 10px;
}
.number-pad:nth-last-child(-n + 3) {
  margin-bottom: 0;
}
.number-btn {
  width: 48px;
  height: 48px;
  outline: none;
  border: none;
}
.number-btn:active {
  background-color: #9dc2ff;
}
.control-container {
  padding: 0 23px;
}
.control-btn {
  padding: 10px;
  background-color: #5bb85f;
  outline: none;
  border: none;
  color: #fff;
  margin-bottom: 10px;
}
.control-btn:active {
  background-color: #43a047;
}
.control-btn.hangup {
  background-color: #b32121;
}
.control-btn.hangup:active {
  background-color: #b32121a9;
}
.historyCall {
  overflow-y: scroll;
  max-height: 300px;
}
.historyCall ul:hover {
  background: #c5c2c2;
}
.historyCall ul li p {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
}
</style>
