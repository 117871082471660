<template>
  <b-modal
    :modal-class="fields.length > 12 ? 'modal-dialog-scrollable' : ''"
    title="Фильтр"
    @close="clear"
    no-close-on-backdrop
    :id="$route.name + 'filter'"
    :ref="'filter_' + $route.name"
    @hide="hide"
    style="display: none">
    <div :style="fields.length > 12 ? 'height: 50vh;' : ''">
      <component
        v-for="(component, index) in componentsList"
        :is="component.key + 'Filter'"
        :value="query"
        :field="component"
        @change="updateData"
        :key="index"></component>
      <b-row>
        <b-col cols="2">
          <p>Лимит</p>
        </b-col>
        <b-col cols="10">
          <input
            v-model="query['limit']"
            type="text"
            v-mask="'####'"
            class="form-control form-control-sm"
            placeholder="Введите лимит (максимум 9999)" />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer class="p-2">
      <button class="btn btn-secondary btn-sm" @click="clear()">Очистить</button>
      <button class="btn btn-primary btn-sm" @click="nameVerification()" v-hotkey="{ enter: nameVerification }">
        Ок
      </button>
    </template>
  </b-modal>
</template>
<script>
import { mask } from "vue-the-mask";
import accessesFilter from "@/views/component/FilterComponents/components/accessesFilter.vue";
import addressFilter from "@/views/component/FilterComponents/components/addressFilter.vue";
import allowancesFilter from "@/views/component/FilterComponents/components/allowancesFilter.vue";
import assignmentByFilter from "./components/assignmentByFilter.vue";
import billsecFilter from "@/views/component/FilterComponents/components/billsecFilter.vue";
import calcTypeFilter from "@/views/component/FilterComponents/components/calcTypeFilter.vue";
import carNumberFilter from "@/views/component/FilterComponents/components/carNumberFilter.vue";
import categoryFilter from "@/views/component/FilterComponents/components/categoryFilter.vue";
import calldateFilter from "@/views/component/FilterComponents/components/calldateFilter.vue";
import channelFilter from "@/views/component/FilterComponents/components/channelFilter.vue";
import cityFilter from "@/views/component/FilterComponents/components/cityFilter.vue";
import clientHintFilter from "@/views/component/FilterComponents/components/clientHintFilter.vue";
import codeDocFilter from "@/views/component/FilterComponents/components/codeDocFilter.vue";
import commentFilter from "@/views/component/FilterComponents/components/commentFilter.vue";
import conditionFilter from "@/views/component/FilterComponents/components/conditionFilter.vue";
import countryFilter from "@/views/component/FilterComponents/components/countryFilter.vue";
import createdByLoginFilter from "@/views/component/FilterComponents/components/createdByLoginFilter.vue";
import createdByFilter from "@/views/component/FilterComponents/components/createdByFilter.vue";
import dateTimeFilter from "@/views/component/FilterComponents/components/dateTimeFilter.vue";
import deliveryPriceFilter from "@/views/component/FilterComponents/components/deliveryPriceFilter.vue";
import descriptionAppFilter from "@/views/component/FilterComponents/components/descriptionAppFilter.vue";
import dispositionFilter from "@/views/component/FilterComponents/components/dispositionFilter.vue";
import districtFilter from "@/views/component/FilterComponents/components/districtFilter.vue";
import divisionFilter from "@/views/component/FilterComponents/components/divisionFilter.vue";
import dopInfoFilter from "@/views/component/FilterComponents/components/dopInfoFilter.vue";
import dstchannelFilter from "@/views/component/FilterComponents/components/dstchannelFilter.vue";
import employeeGroupFilter from "@/views/component/FilterComponents/components/employeeGroupFilter.vue";
import fastAddressCategoryFilter from "@/views/component/FilterComponents/components/fastAddressCategoryFilter.vue";
import fastAddressFilter from "@/views/component/FilterComponents/components/fastAddressFilter.vue";
import firstNameFilter from "@/views/component/FilterComponents/components/firstNameFilter.vue";
import formNameFilter from "@/views/component/FilterComponents/components/formNameFilter.vue";
import fromAddressFilter from "@/views/component/FilterComponents/components/fromAddressFilter.vue";
import genderFilter from "@/views/component/FilterComponents/components/genderFilter.vue";
import homeTypeFilter from "@/views/component/FilterComponents/components/homeTypeFilter.vue";
import idFilter from "@/views/component/FilterComponents/components/idFilter.vue";
import isActiveFilter from "@/views/component/FilterComponents/components/isActiveFilter.vue";
import iso2Filter from "@/views/component/FilterComponents/components/iso2Filter.vue";
import lastdataFilter from "@/views/component/FilterComponents/components/lastdataFilter.vue";
import lastNameFilter from "@/views/component/FilterComponents/components/lastNameFilter.vue";
import loginFilter from "@/views/component/FilterComponents/components/loginFilter.vue";
import minPriceFilter from "@/views/component/FilterComponents/components/minPriceFilter.vue";
import modelFilter from "@/views/component/FilterComponents/components/modelFilter.vue";
import nameFilter from "@/views/component/FilterComponents/components/nameFilter.vue";
import nameRuFilter from "@/views/component/FilterComponents/components/nameRuFilter.vue";
import patronymicFilter from "@/views/component/FilterComponents/components/patronymicFilter.vue";
import performerPhotocontrolstatusnameFilter from "@/views/component/FilterComponents/components/performerPhotocontrolstatusnameFilter.vue";
import phoneFilter from "@/views/component/FilterComponents/components/phoneFilter.vue";
import phoneNumberFilter from "@/views/component/FilterComponents/components/phoneNumberFilter.vue";
import populationFilter from "@/views/component/FilterComponents/components/populationFilter.vue";
import positionsFilter from "@/views/component/FilterComponents/components/positionsFilter.vue";
import priceFilter from "@/views/component/FilterComponents/components/priceFilter.vue";
import regionFilter from "@/views/component/FilterComponents/components/regionFilter.vue";
import serialNumberPassportFilter from "@/views/component/FilterComponents/components/serialNumberpassportFilter.vue";
import serialsNumberFilter from "@/views/component/FilterComponents/components/serialsNumberFilter.vue";
import statusFilter from "@/views/component/FilterComponents/components/statusFilter.vue";
import streetFilter from "@/views/component/FilterComponents/components/streetFilter.vue";
import streetTypeFilter from "@/views/component/FilterComponents/components/streetTypeFilter.vue";
import synonymFilter from "@/views/component/FilterComponents/components/synonymFilter.vue";
import tariffFilter from "@/views/component/FilterComponents/components/tarrifFilter.vue";
import toAddressesFilter from "@/views/component/FilterComponents/components/toAddressesFilter.vue";
import typeFilter from "@/views/component/FilterComponents/components/typeFilter.vue";
import uniqueFilter from "@/views/component/FilterComponents/components/uniqueFilter.vue";
import villageFilter from "@/views/component/FilterComponents/components/villageFilter.vue";
import zoneFilter from "@/views/component/FilterComponents/components/zoneFilter.vue";
import transactionCategoryFilter from "@/views/component/FilterComponents/components/transactionCategoryFilter.vue";
import amountFilter from "@/views/component/FilterComponents/components/amountFilter.vue";
import performerLoginFilter from "@/views/component/FilterComponents/components/performerLoginFilter.vue";
import carBrandFilter from "@/views/component/FilterComponents/components/carBrandFilter.vue";
import categoryCarFilter from "@/views/component/FilterComponents/components/categoryCarFilter.vue";
import classCarFilter from "@/views/component/FilterComponents/components/classCarFilter.vue";
import srcFilter from "@/views/component/FilterComponents/components/srcFilter.vue";
import dstFilter from "@/views/component/FilterComponents/components/dstFilter.vue";
import isBlockFilter from "@/views/component/FilterComponents/components/isBlockFilter.vue";
import statusAutoFilter from "@/views/component/FilterComponents/components/statusAutoFilter.vue";
import carInfoFilter from "@/views/component/FilterComponents/components/carInfoFilter.vue";
import infoForOperatorFilter from "./components/infoForOperatorFilter.vue";
import infoForDriverFilter from "./components/infoForDriversFilter.vue";
import orgTypeFilter from "@/views/component/FilterComponents/components/orgTypeFilter.vue";
import descriptionFilter from "@/views/component/FilterComponents/components/descriptionFilter.vue";
import orderIdFilter from "./components/orderIdFilter.vue";
import perfomerIdFilter from "./components/perfomerIdFilter.vue";
import memoIdFilter from "./components/memoIdFilter.vue";
import driverProfileIdFilter from "./components/driverProfileIdFilter.vue";
import clientStatusFilter from "./components/clientStatusFilter.vue";
import perfomerPhoneFilter from "./components/perfomerPhoneFilter.vue";
import dopPhoneFilter from "./components/dopPhoneFilter.vue";
import typeDescriptionFilter from "./components/typeDescriptionFilter.vue";
import statusNameFilter from "./components/statusNameFilter.vue";
import createdByFioFilter from "./components/createdByFioFilter.vue";
import hasPhotoFilter from "./components/hasPhotoFilter.vue";
import performerFilter from "./components/performerFilter.vue";
import carFilter from "./components/carFilter.vue";
import carClassFilter from "./components/carClassFilter.vue";
import commissionFilter from "@/views/component/FilterComponents/components/commissionFilter.vue";
import yearOfIssueFilter from "./components/yearOfIssueFilter.vue";
import divisionPhotoFilter from "./components/divisionPhotoFilter.vue";
import clientStatusInfoFilter from "./components/clientStatusInfoFilter.vue";
import dateOfBirthFilter from "./components/dateOfBirthFilter.vue";
import carColorFilter from "./components/carColorFilter.vue";
import bodyTypeFilter from "./components/bodyTypeFilter.vue";
import classFilter from "./components/classFilter.vue";
import carLengthFilter from "./components/carLengthFilter.vue";
import carWidthFilter from "./components/carWidthFilter.vue";
import carHeigthFilter from "./components/carHeigthFilter.vue";
import countSeatFilter from "./components/countSeatFilter.vue";
import divisionOrderFilter from "./components/divisionOrderFilter.vue";
import driversDivisionFilter from "./components/driversDivisionFilter.vue";
import resultFilter from "./components/resultFilter.vue";
import performerDistanceFilter from "./components/performerDistanceFilter.vue";
import performerRatingFilter from "./components/performerRatingFilter.vue";
import commissionOrderFilter from "./components/commissionOrderFilter.vue";
import driverBalanceFilter from "./components/driverBalanceFilter.vue";
import lgtFilter from "./components/lgtFilter.vue";
import latFilter from "./components/latFilter.vue";
import fullNameFilter from "./components/fullNameFilter.vue";
import sumForFilter from "./components/sumForFilter.vue";
import sumAfterFilter from "./components/sumAfterFilter.vue";
import typeNameFilter from "./components/typeNameFilter.vue";
import createdUserFilter from "./components/createdUserFilter.vue";
import codeTypeDocFilter from "./components/codeTypeDocFilter.vue";
import nameForPerfomerFilter from "./components/nameForPerfomerFilter.vue";
import nameForClientFilter from "./components/nameForClientFilter.vue";
import levelFilter from "./components/levelFilter.vue";
import divisionNameFilter from "./components/divisionNameFilter.vue";
import zoneStateFilter from "./components/zoneStateFilter.vue";
import performerBalanceFilter from "./components/performerBalanceFilter.vue";
import fullNamePerformerFilter from "./components/fullNamePerformerFilter.vue";
import performerTransportModelFilter from "./components/performerTransportModelFilter.vue";
import performerTransportColorFilter from "./components/performerTransportColorFilter.vue";
import performerTransportNumberFilter from "./components/performerTransportNumberFilter.vue";
import performerTransportCategoryFilter from "./components/performerTransportCategoryFilter.vue";
import performerTransportClassFilter from "./components/performerTransportClass.vue";
import performerTransportDopFilter from "./components/performerTransportDopFilter.vue";
import performerTransportIdFilter from "./components/performerTransportIdFilter.vue";
import performerTransportCountFilter from "./components/performerTransportCountFilter.vue";
import performerLngFilter from "./components/performerLngFilter.vue";
import performerLatFilter from "./components/performerLatFilter.vue";
import performerIsActiveFilter from "./components/performerIsActiveFilter.vue";
import priceRoundingFilter from "./components/priceRoundingFilter.vue";
import radiusAutoAssignmentFilter from "./components/radiusAutoAssignmentFilter.vue";
import typeTariffPerformerFilter from "@/views/component/FilterComponents/components/typeTariffPerformerFilter.vue";
import carClassNameFilter from "@/views/component/FilterComponents/components/carClassNameFilter.vue";
import sortNumberFilter from "@/views/component/FilterComponents/components/sortNumberFilter.vue";
import basisFilter from "@/views/component/FilterComponents/components/basisFilter.vue";
import blockedFilter from "@/views/component/FilterComponents/components/blockedFilter.vue";
import pricesFilter from "@/views/component/FilterComponents/components/pricesFilter.vue";
import userLoginFilter from "@/views/component/FilterComponents/components/userLoginFilter.vue";
import patternAllowanceNameFilter from "@/views/component/FilterComponents/components/patternAllowanceNameFilter.vue";
import addrTypeRelationFilter from "@/views/component/FilterComponents/components/addrTypeRelationFilter.vue";
import replaceBasePriceOrderFilter from "@/views/component/FilterComponents/components/replaceBasePriceOrderFilter.vue";
import requireParameterFilter from "@/views/component/FilterComponents/components/requireParameterFilter.vue";
import isRequireFilter from "@/views/component/FilterComponents/components/isRequireFilter.vue";
import addMinPriceFilter from "@/views/component/FilterComponents/components/addMinPriceFilter.vue";
import isMinPriceFilter from "@/views/component/FilterComponents/components/isMinPriceFilter.vue";
import discountShowFilter from "@/views/component/FilterComponents/components/discountShowFilter.vue";
import sortFilter from "@/views/component/FilterComponents/components/sortFilter.vue";
import typeTariffNameFilter from "@/views/component/FilterComponents/components/typeTariffNameFilter.vue";
import dateStartTimeFilter from "@/views/component/FilterComponents/components/dateStartTimeFilter.vue";
import addresStreetFilter from "@/views/component/FilterComponents/components/addresStreetFilter.vue";
import divisionBaseTariffFilter from "@/views/component/FilterComponents/components/divisionBaseTariffFilter.vue";
import cityCodeFilter from "./components/cityCodeFilter.vue";
import streetCodeFilter from "./components/streetCodeFilter.vue";
import numberFilter from "./components/numberFilter.vue";
import typeQrFilter from "./components/typeQrFilter.vue";
import dateFilter from "./components/dateFilter.vue";
import fuelTypeNameFilter from "./components/fuelTypeNameFilter.vue";
import paymentTypeFilter from "./components/paymentTypeFilter.vue";
import stateStatusFilter from "./components/stateStatusFilter.vue";
import statusFormsFilter from "./components/statusFormsFilter.vue";
import dateTimeFormFilter from "./components/dateTimeFormFilter.vue";

export default {
  directives: { mask },
  props: {
    fields: {
      type: Array,
      default: [],
    },
    name: {
      type: String,
      default: "",
    },
  },
  components: {
    clientStatusInfoFilter,
    accessesFilter,
    yearOfIssueFilter,
    addressFilter,
    allowancesFilter,
    assignmentByFilter,
    amountFilter,
    bodyTypeFilter,
    billsecFilter,
    carFilter,
    carColorFilter,
    carLengthFilter,
    carWidthFilter,
    carHeigthFilter,
    carClassFilter,
    calcTypeFilter,
    carNumberFilter,
    categoryFilter,
    calldateFilter,
    channelFilter,
    createdUserFilter,
    classFilter,
    countSeatFilter,
    codeTypeDocFilter,
    cityFilter,
    clientHintFilter,
    clientStatusFilter,
    codeDocFilter,
    conditionFilter,
    commentFilter,
    countryFilter,
    createdByLoginFilter,
    createdByFilter,
    createdByFioFilter,
    dateTimeFilter,
    dateOfBirthFilter,
    deliveryPriceFilter,
    descriptionAppFilter,
    dispositionFilter,
    divisionOrderFilter,
    driversDivisionFilter,
    driverBalanceFilter,
    districtFilter,
    divisionFilter,
    divisionPhotoFilter,
    dopInfoFilter,
    dopPhoneFilter,
    dstchannelFilter,
    dstFilter,
    driverProfileIdFilter,
    employeeGroupFilter,
    fastAddressCategoryFilter,
    fastAddressFilter,
    firstNameFilter,
    formNameFilter,
    fromAddressFilter,
    fullNameFilter,
    genderFilter,
    homeTypeFilter,
    hasPhotoFilter,
    idFilter,
    isActiveFilter,
    iso2Filter,
    isBlockFilter,
    infoForOperatorFilter,
    infoForDriverFilter,
    lastdataFilter,
    lastNameFilter,
    lgtFilter,
    latFilter,
    loginFilter,
    memoIdFilter,
    minPriceFilter,
    modelFilter,
    nameFilter,
    nameRuFilter,
    nameForPerfomerFilter,
    nameForClientFilter,
    resultFilter,
    performerRatingFilter,
    patronymicFilter,
    performerPhotocontrolstatusnameFilter,
    performerLoginFilter,
    performerDistanceFilter,
    phoneFilter,
    phoneNumberFilter,
    perfomerPhoneFilter,
    populationFilter,
    positionsFilter,
    perfomerIdFilter,
    priceFilter,
    performerFilter,
    regionFilter,
    serialNumberPassportFilter,
    serialsNumberFilter,
    statusFilter,
    streetFilter,
    streetTypeFilter,
    synonymFilter,
    statusAutoFilter,
    srcFilter,
    statusNameFilter,
    sumForFilter,
    sumAfterFilter,
    tariffFilter,
    commissionFilter,
    toAddressesFilter,
    transactionCategoryFilter,
    typeFilter,
    typeNameFilter,
    typeDescriptionFilter,
    uniqueFilter,
    villageFilter,
    zoneFilter,
    carBrandFilter,
    categoryCarFilter,
    classCarFilter,
    carInfoFilter,
    orgTypeFilter,
    orderIdFilter,
    commissionOrderFilter,
    descriptionFilter,
    levelFilter,
    divisionNameFilter,
    zoneStateFilter,
    performerBalanceFilter,
    fullNamePerformerFilter,
    performerTransportModelFilter,
    performerTransportColorFilter,
    performerTransportNumberFilter,
    performerTransportCategoryFilter,
    performerTransportClassFilter,
    performerTransportDopFilter,
    performerTransportIdFilter,
    performerTransportCountFilter,
    performerLngFilter,
    performerLatFilter,
    performerIsActiveFilter,
    priceRoundingFilter,
    radiusAutoAssignmentFilter,
    typeTariffPerformerFilter,
    carClassNameFilter,
    sortNumberFilter,
    basisFilter,
    blockedFilter,
    pricesFilter,
    userLoginFilter,
    patternAllowanceNameFilter,
    addrTypeRelationFilter,
    replaceBasePriceOrderFilter,
    requireParameterFilter,
    isRequireFilter,
    addMinPriceFilter,
    isMinPriceFilter,
    discountShowFilter,
    sortFilter,
    typeTariffNameFilter,
    dateStartTimeFilter,
    addresStreetFilter,
    divisionBaseTariffFilter,
    cityCodeFilter,
    streetCodeFilter,
    numberFilter,
    typeQrFilter,
    dateFilter,
    fuelTypeNameFilter,
    paymentTypeFilter,
    stateStatusFilter,
    statusFormsFilter,
    dateTimeFormFilter,
  },
  data() {
    return {
      query: {
        filter_state: "",
      },
      tableData: [],
    };
  },
  mounted() {
    this.query = JSON.parse(localStorage.getItem(this.$route.name + "data")) || {};
  },
  methods: {
    updateData(newData) {
      if (this.query[newData.field] !== undefined) {
        this.query[newData.field] = newData.val;
      } else {
        let newPropertyObject = {};
        newPropertyObject[newData.field] = newData.val;
        this.query = Object.assign({}, this.query, newPropertyObject);
      }
    },
    clear() {
      this.query = {};
      this.$store.state.filterPlus.filterPlus = null;
      this.$store.state.filterPlus.addFilterPlus = false;
      this.$store.state.filterPlus.tableIndex = null;
      localStorage.removeItem(this.$route.name + "data");
    },
    nameVerification() {
      let page = this.name;
      page ? this.sendToParent(page) : this.sendToParent(this.$route.name);
    },
    sendToParent(page) {
      this.$emit("sendToParent", this.tableData);
      if (this.query.limit) {
        this.query.limit = this.query.limit;
      } else {
        this.query.limit = 100;
      }
      let sorting = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      this.$bvModal.hide(this.$route.name + "filter");
      this.$store.commit("LOADER_FILTER", true);
      let queryParams = {
        ...this.query,
        cityCode: this.query.cityCode ? this.query.cityCode : localStorage.getItem("city"),
        slag: this.query.slag ? this.query.slag : localStorage.getItem("sleg"),
      };
      if (sorting) {
        queryParams.filter_state = sorting.filter_state;
      }
      this.$store.state.filterPlus.tableIndex = null;
      localStorage.setItem(this.$route.name + "data", JSON.stringify(queryParams));
      this.$http.get(`${page}`, { params: queryParams }).then((res) => {
        this.tableData = res.data;
        this.$emit("sendToParent", this.tableData);
        this.tableData = [];
        this.$store.commit("LOADER_FILTER", false);
      });
      this.$store.state.filterPlus.addFilterPlus = false;
    },
    hide() {
      this.$store.state.filterPlus.addFilterPlus = false;
    },
  },
  computed: {
    componentsList() {
      let list = [];
      this.fields.forEach((field) => {
        if (field.key != "checkbox") {
          let name = field.key;
          if (name.indexOf("_") !== -1) {
            for (let i = 0; i < name.split("_").length; i++) {
              name =
                name.substring(0, name.indexOf("_") + 1) +
                name.substring(name.indexOf("_") + 1, name.indexOf("_") + 2).toUpperCase() +
                name.substring(name.indexOf("_") + 2);
              name = name.replace("_", "");
            }
          }
          if (name.indexOf(".") !== -1) {
            name = name.substring(0, name.indexOf("."));
          }
          let obj = { label: field.label, key: name };
          list.push(obj);
        }
      });
      return list;
    },
  },
  watch: {
    $route(to, from) {
      if (from.fullPath !== to.fullPath) {
        this.$store.state.filterPlus.filterPlus = null;
      }
    },
  },
};
</script>
