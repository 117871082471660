<template>
  <div>
    <b-row>
      <b-col cols="2">
        <p>{{ field.label }}</p>
      </b-col>
      <b-col cols="5">
        <b-form-select
          size="sm"
          :options="Options"
          :value="value.filter_status_condition"
          @input="updateModel($event, 'filter_status_condition')" />
      </b-col>
      <b-col cols="5">
        <b-form-input
          :placeholder="field.label"
          size="sm"
          :value="value.filter_status"
          @input="updateModel($event, 'filter_status')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import filterOptions from "@/utils/constants/filerConstants/parametrs";
export default {
  props: ["value", "field"],
  name: "statusForms",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      Options: filterOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      if (name === "filter_status" || name === "filter_status_condition") {
        if (value === "") {
          value = null;
        }
        this.$emit("change", { val: value, field: name });
      }

      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "status" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_status";
      this.updateModel(value, name);
    }
  },
};
</script>

<style lang="scss" scoped></style>
