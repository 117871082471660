<template>
  <b-row>
    <b-col cols="2">
      <p>Куда</p>
    </b-col>
    <b-col cols="5">
      <b-form-select
        size="sm"
        :options="to_addressOptions"
        :value="value.filter_to_address_condition"
        @input="updateModel($event, 'filter_to_address_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input
        placeholder="Куда"
        size="sm"
        :value="value.filter_to_address"
        @input="updateModel($event, 'filter_to_address')" />
    </b-col>
  </b-row>
</template>
<script>
import filterOptions from "@/utils/constants/filerConstants/parametrs";
export default {
  props: ["value"],
  name: "to_addresses",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      to_addressOptions: filterOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      if (name === "filter_to_address" || name === "filter_to_address_condition") {
        if (value === "") {
          value = null;
        }
        this.$emit("change", { val: value, field: name });
      }

      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "to_addresses[0].title" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_to_address";
      this.updateModel(value, name);
    }
  },
};
</script>
