import useJwt from "@/@core/auth/jwt/jwtDefaultConfig";

const status =
  localStorage.getItem(useJwt.storageSipStatusName) === "true" ? true : false;
export default {
  namespaced: true,
  state: {
    callerState: false,
    status: status,
    sessionState: false,
    phoneNumber: null,
    isInitiator: false,
    isOrderModalOpened: false,
    isMuted: false,
    loader: false,
  },
  getters: {
    currentCallerState: (state) => {
      return state;
    },
    currentStatus: (state) => {
      return state;
    },
    currentSessionState: (state) => {
      return state;
    },
    currentPhoneNumber: (state) => {
      return state;
    },
    currentIsInitiatorState: (state) => {
      return state;
    },
    currentIsMuted: (state) => {
      return state;
    },
  },
  mutations: {
    UPDATE_CALLER_STATE(state, val) {
      state.callerState = val;
    },
    UPDATE_PHONE_NUMBER(state, val) {
      state.phoneNumber = val;
    },
    UPDATE_STATUS(state, val) {
      localStorage.setItem(useJwt.storageSipStatusName, val);
      state.status = val;
    },
    UPDATE_SESSION_STATE(state, val) {
      state.sessionState = val;
    },
    UPDATE_IS_INITIATOR_STATE(state, val) {
      state.isInitiator = val;
    },
    UPDATE_IS_ORDER_MODAL_OPENED_STATE(state, val) {
      state.isOrderModalOpened = val;
    },
    UPDATE_IS_MUTED(state, val) {
      state.isMuted = val;
    },
    UPDATE_LOADER(state, val) {
      state.loader = val;
    },
  },
  actions: {},
};
