<template>
  <b-row>
    <b-col cols="2">
      <p>Категория</p>
    </b-col>
    <b-col cols="10">
      <v-select
        :value="value.filter_category_car_id"
        :reduce="(options) => options.id"
        label="name"
        :options="car_brands"
        @input="updateModel($event, 'filter_category_car_id')" />
    </b-col>
  </b-row>
</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  props: ["value"],
  name: "categoryCar",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      car_brands: [],
    };
  },
  methods: {
    updateModel(value, name) {
      this.$emit("change", { val: value, field: name });
    },
  },
  mounted() {
    this.$http.get("directory/car-categories").then((res) => {
      this.car_brands = res.data;
    });
  },
};
</script>
