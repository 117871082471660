import Vue from "vue";
import axios from "axios";
import { baseURL } from "./constant.js";
import useJwt from "@/@core/auth/jwt/jwtDefaultConfig.js";

Vue.prototype.$baseURL = baseURL;

const axiosIns = axios.create({
  baseURL: Vue.prototype.$baseURL,
});

Vue.prototype.$http = axiosIns;

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response?.status === 401) {
      localStorage.removeItem(useJwt.storageTokenKeyName);
      localStorage.removeItem(useJwt.storageUserDataName);
      window.location.href = "/login";
    }
    throw error;
  }
);

export default axiosIns;
