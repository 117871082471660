<template>
  <b-row>
    <b-col cols="2">
      <p>Автомобиль</p>
    </b-col>
    <b-col cols="5">
      <b-form-select
        size="sm"
        :options="firstNameOptions"
        :value="value.filter_car_condition"
        @input="updateModel($event, 'filter_car_condition')" />
    </b-col>
    <b-col cols="5">
      <b-form-input
        placeholder="Автомобиль"
        size="sm"
        :value="value.filter_car"
        @update="updateModel($event, 'filter_car')" />
    </b-col>
  </b-row>
</template>
<script>
import filterOptions from "@/utils/constants/filerConstants/parametrs";
export default {
  props: ["value"],
  name: "car",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      firstNameOptions: filterOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      this.$emit("change", { val: value, field: name });
    },
  },
  mounted() {
    // prettier-ignore
    if (this.$store.state.filterPlus.filterPlus !== null 
    && this.$store.state.filterPlus.keyFilter === 'body_type' 
    || (this.$store.state.filterPlus.keyFilter === 'performer_car' && this.$store.state.filterPlus.addFilterPlus)) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'filter_car';
            this.updateModel(value, name);
        }
  },
};
</script>
